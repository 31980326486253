import Text from 'ol/style/Text'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import {stripes} from './patterns'

import {readableTextColor, withAlpha} from '@helpers/color'

export const setLabel = textFunction => (style, feature, resolution) => {
  style.getText().setText(resolution < 50 ? textFunction(feature, resolution) || '' : '')
}

export const setWidth = widthFunction => (style, feature, resolution) => {
  style.getStroke().setWidth(widthFunction(feature, resolution))
}

export const setColor = (colorFunction, opacity = 1) => (style, feature, resolution) => {
  const color = colorFunction(feature, resolution)

  const strokeColor = color
    ? color.stroke || withAlpha(color, opacity)
    : 'rgba(0, 0, 0, 0)'

  const fillColor = color
    ? color.fill || withAlpha(color, 0.75 * opacity)
    : 'rgba(0, 0, 0, 0)'

  const text = style.getText()
  const stroke = style.getStroke()
  const fill = style.getFill()
  const image = style.getImage()

  if (text) {
    const textColor = color.textColor || strokeColor
    text.getStroke().setColor(textColor)
    text.getFill().setColor(readableTextColor(textColor))
  }
  if (stroke) {
    stroke.setColor(strokeColor)
  }
  if (fill) {
    fill.setColor(stroke ? fillColor : strokeColor)
  }
  if (image) {
    const imageStroke = image.getStroke()
    const imageFill = image.getFill()

    if (imageStroke) {
      imageStroke.setColor(strokeColor)
    }
    if (imageFill) {
      imageFill.setColor(imageStroke ? fillColor : strokeColor)
    }
  }
}

export const modifierStack = (style, modifiers) => (feature, resolution) => {
  modifiers.forEach(f => f(style, feature, resolution))
  return style
}

export const labelText = ({color = 'black', textColor = 'white', text = 'placeholder', font = '14px CalibreWeb', overflow = true} = {}) => new Text({
  textAlign: 'center',
  textBaseline: 'middle',
  text: text,
  font: font,
  fill: new Fill({
    color: textColor
  }),
  stroke: new Stroke({
    color,
    width: 3
  }),
  overflow: overflow
  // offsetX: 8,
  // offsetY: 3.5
  // rotation: -Math.PI / 12
})

export const stripedFill = (stripeColor, backgroundColor) => new Fill({
  color: stripes(stripeColor, backgroundColor)
})
