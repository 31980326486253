import {modifierStack, setColor, setLabel} from '@helpers/openlayers/styles'
import {colorFunctions} from '@helpers/map-color-schemes'
import {zoneBase} from './base'
import {format} from 'src/js/i18n/conversion'
import {phbbFeatureColorFunctions} from './phbb/phbb-colors'

const setUniversalLabel = setLabel((feature) => {
  const {zoneNumber, value, unit, labelText, hideLabel, displayValue} = feature.getProperties()

  if (hideLabel) return ''
  if (labelText) return labelText

  if (zoneNumber !== undefined) {
    if (value !== undefined) {
      return unit
        ? `Zone ${zoneNumber}: ${format(value, 'f2')} ${unit}`
        : `Zone ${zoneNumber}: ${format(value, 'f2')}`
    } else {
      return `Zone ${zoneNumber}`
    }
  } else if (value !== undefined || displayValue !== undefined) {
    return unit
      ? `${format(value || displayValue, 'f2')} ${unit}`
      : `${format(value || displayValue, 'f2')}`
  } else {
    return 'ERROR'
  }
})

const makeMapStyle = (featureColorFunction, opacity = 0.85) => modifierStack(zoneBase, [
  setColor(featureColorFunction, opacity),
  setUniversalLabel
])

export const agrosat = makeMapStyle(feature => {
  const {zoneNumber, zoneCount} = feature.getProperties()

  return colorFunctions.agrosat(zoneNumber, zoneCount)
})

export const pastel = makeMapStyle(feature => {
  const {zoneNumber, zoneCount} = feature.getProperties()

  return colorFunctions.pastel(zoneNumber, zoneCount)
})

export const lufa = makeMapStyle(feature => {
  const {value, nutrient} = feature.getProperties()

  return colorFunctions.lufa(value, nutrient)
})

export const relative = makeMapStyle(feature => {
  const {zoneNumber, zoneCount, value, valueRange} = feature.getProperties()

  if (value !== undefined && valueRange !== undefined) {
    return colorFunctions.relative(value, valueRange)
  } else if (zoneNumber !== undefined && zoneCount !== undefined) {
    return colorFunctions.relative(zoneNumber, [1, zoneCount])
  } else {
    return '#f0f'
  }
})

export const absolute = makeMapStyle(feature => {
  const {value, valueRange} = feature.getProperties()

  return colorFunctions.absolute(value, valueRange)
})

export const autoZoneMapDebug = makeMapStyle(feature => {
  const {zoneMidpoint} = feature.getProperties()

  return colorFunctions.autoZoneMapDebug(zoneMidpoint)
})

export const phbb = (opacity, mapCategory) => {
  if (!phbbFeatureColorFunctions[mapCategory]) {
    console.error('no color function for this map category found, so the default is used')

    return makeMapStyle(phbbFeatureColorFunctions.defaultLinear, opacity)
  } else {
    return makeMapStyle(phbbFeatureColorFunctions[mapCategory], opacity)
  }
}
