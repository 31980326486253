import Style from 'ol/style/Style'
import Stroke from 'ol/style/Stroke'
import Fill from 'ol/style/Fill'
import Circle from 'ol/style/Circle'

import {labelText, stripedFill} from '@helpers/openlayers/styles'

export const polygonBase = new Style({
  stroke: new Stroke({
    color: 'rgba(200, 200, 200, 1)',
    width: 2
  }),
  fill: new Fill({color: 'rgba(200, 200, 200, 0.4)'}),
  text: labelText({text: '', color: 'rgb(60, 60, 60)'})
})

export const zoneBase = new Style({
  stroke: new Stroke({
    color: 'rgba(200, 200, 200, 1)',
    width: 2
  }),
  fill: new Fill({color: 'rgba(200, 200, 200, 0.4)'}),
  text: labelText({text: '', color: 'rgb(60, 60, 60)', overflow: false})
})

export const polygonClusterBase = new Style({
  image: new Circle({
    radius: 15,
    fill: new Fill({color: 'rgba(255, 130, 40, 0.8)'})
  }),
  text: labelText({color: 'transparent'})
})

export const pointBase = new Style({
  image: new Circle({
    radius: 2,
    fill: new Fill({color: 'rgba(255, 0, 0)'})
  })
})

export const stripedPolygonBase = new Style({
  stroke: new Stroke({
    color: 'black',
    width: 2
  }),
  fill: stripedFill('black', 'transparent'),
  text: labelText({color: 'black'})
})

export const stripedPolygonBaseWithoutText = new Style({
  stroke: new Stroke({
    color: 'black',
    width: 2
  }),
  fill: stripedFill('black', 'transparent')
})

export const border = new Style({
  stroke: new Stroke({
    color: 'rgba(150, 200, 255, 0.8)',
    width: 1
  }),
  fill: new Fill({
    color: 'rgba(150, 200, 255, 0.6)'
  }),
  text: labelText({color: 'rgb(35, 35, 35)', textColor: 'rgba(255, 255, 255, 0.8)'})
})

// TODO: might be obsolete
export const borderBase = new Style({
  stroke: new Stroke({
    color: '#7098ff',
    width: 2
  }),
  text: labelText({text: '', color: '#7098ff'})
})

export const grid = [
  new Style({
    image: new Circle({
      radius: 10,
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 0.5)',
        width: 1
      })
    }),
    stroke: new Stroke({
      color: 'rgba(0, 0, 0, 0.5)',
      width: 1
    })
  })
]

export const fieldBorderActive = [new Style({
  stroke: new Stroke({
    color: 'rgb(255, 255, 255)',
    width: 2
  })
})]

export const fieldBorderInactive = [new Style({
  stroke: new Stroke({
    color: 'rgb(128, 128, 128)',
    width: 1,
    lineDash: [10, 5]
  }),
  fill: new Fill({
    color: 'rgba(0, 0, 0, 0.4)'
  })
})]
