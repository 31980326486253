export const getZoneNumbers = map => {
  return map.zones !== undefined ? map.zones.map((zone, i) => zone.number || (zone.name ? Number(zone.name) : (i + 1))) : []
}

export function getZoneCount (map) {
  const zoneNumbers = getZoneNumbers(map)

  // NOTE countOfClasses or intendedZoneCount exists only for a subset of zoneMaps
  const zoneCountV1 = map.metaData && map.metaData.countOfClasses
  const zoneCountV2 = map.metaData && map.metaData.algorithmSummary && map.metaData.algorithmSummary.intendedZoneCount
  const zoneCountFallback = Math.max(...zoneNumbers)

  return zoneCountV1 || zoneCountV2 || zoneCountFallback
}
