import {interpolateMagma, interpolateCool, interpolateRdYlGn} from 'd3-scale-chromatic'
import {getZoneNumbers, getZoneCount} from '@helpers/zone-map'

import colorDefinitions from '@frs/map-styles/basic-fertilization/colors'
import * as mapStyles from '@frs/map-styles/maps'
import {phbbValueColorFunctions} from '@frs/map-styles/phbb/phbb-colors'
import {interpolateRegularGradient} from '@helpers/color'
import parse from 'wellknown'
import {format} from 'src/js/i18n/conversion'

export const lufaRanges = {
  ph: [0, 250],
  phosphorus: [0, 250],
  potassium: [0, 250],
  magnesium: [0, 250]
}

export const absoluteRange = [0, 250]

export const categoryNutrientMapping = {
  BasicFertilization_Magnesium: 'magnesium',
  BasicFertilization_Potassium: 'potassium',
  BasicFertilization_Phosphorus: 'phosphorus',
  BasicFertilization_pH: 'ph' // TODO verify correctness of enum member
}

export const getMapMetrics = map => {
  if (!map) return

  const values = map.zones !== undefined ? map.zones.map(zone => zone.value === undefined ? zone.number : zone.value) : []
  const valueRange = [Math.min(...values), Math.max(...values)]

  const zoneNumbers = getZoneNumbers(map)
  const zoneCount = getZoneCount(map)

  const zoneMidpoints = map.zones.map((zone, i) => {
    const thresholds = map.metaData ? map.metaData.classificationThresholds : null
    if (!thresholds || !thresholds.length) return null

    const number = zoneNumbers[i]
    const lowerBound = thresholds[thresholds.length - number - 1]
    const upperBound = thresholds[thresholds.length - number]
    return (Math.max(lowerBound, -1) + Math.min(upperBound, 1)) / 2
  })

  return {
    values,
    valueRange,
    zoneNumbers,
    zoneCount,
    zoneMidpoints
  }
}

export function mapToColors (map, colorScheme) {
  switch (colorScheme) {
  case 'absolute': {
    return map.zones.map(zone => colorFunctions.absolute(zone.value, absoluteRange))
  }
  case 'relative': {
    const {valueRange} = getMapMetrics(map)

    return map.zones.map(zone => colorFunctions.relative(zone.value === undefined ? zone.number : zone.value, valueRange))
  }
  case 'lufa': {
    const nutrient = categoryNutrientMapping[map.category]

    return map.zones.map(zone => colorFunctions.lufa(zone.value, nutrient))
  }
  case 'agrosat': {
    const {zoneCount, zoneNumbers} = getMapMetrics(map)

    return map.zones.map((zone, i) => colorFunctions.agrosat(zoneNumbers[i], zoneCount))
  }
  case 'pastel': {
    const {zoneCount, zoneNumbers} = getMapMetrics(map)

    return map.zones.map((zone, i) => colorFunctions.pastel(zoneNumbers[i], zoneCount))
  }
  case 'autoZoneMapDebug': {
    const {zoneMidpoints} = getMapMetrics(map)

    return map.zones.map((zone, i) => colorFunctions.autoZoneMapDebug(zoneMidpoints[i]))
  }
  }
}

export function mapToColorLookup (map, colorScheme) {
  const zoneColors = mapToColors(map, colorScheme)

  const lookup = {}

  map.zones.forEach((zone, index) => {
    lookup[zone.id] = zoneColors[index]
  })

  return lookup
}

export function mapToGeoJsonFeatures (map, colorScheme) {
  if (!map || map.zones === undefined) return []

  const {zoneNumbers, valueRange, zoneCount, zoneMidpoints} = getMapMetrics(map)
  return map.zones !== undefined ? map.zones.map((zone, i) => ({
    type: 'Feature',
    geometry: parse(zone.geometry),
    properties: {
      zoneMapId: map.id,
      zoneId: zone.id,
      zoneNumber: zoneNumbers[i],
      zoneCount,
      value: map.category === 'LimeApplicationMap' ? format(zone.value, 'f0') : zone.value,
      valueRange: colorScheme === 'absolute' ? absoluteRange : valueRange,
      nutrient: categoryNutrientMapping[map.category],
      zoneMidpoint: zoneMidpoints[i]
    }
  })) : []
}

export function mapToStyle (map, colorScheme) {
  return mapStyles[colorScheme]
}

export function getStyle (colorScheme) {
  return mapStyles[colorScheme]
}

const normalize = (value, [min, max]) => min < max
  ? Math.max(0, Math.min(1, (value - min) / (max - min)))
  : 0.5

export const colorFunctions = {
  absolute: (value, range) => interpolateMagma(normalize(value, range)),
  relative: (value, range) => interpolateCool(normalize(value, range)),
  // NOTE only works for odd zoneCount
  agrosat (zoneNumber, zoneCount) {
    if (!zoneNumber) return colorDefinitions.managementZoneMap[1][0]

    const N = zoneCount

    const colorMapZoneCount = N + (N % 2 ? 0 : 1)

    const colorIndex = N % 2 === 0 && zoneNumber > N / 2
      ? zoneNumber
      : zoneNumber - 1

    return colorDefinitions.managementZoneMap[colorMapZoneCount][colorIndex] || '#ff00ff'
  },
  pastel (zoneNumber, zoneCount) {
    const t = normalize(zoneNumber, [1, zoneCount])
    return interpolateRegularGradient(colorDefinitions.pastel, t)
  },
  lufa (value, nutrient) {
    const range = lufaRanges[nutrient]
    const baseColor = colorDefinitions.nutrientBaseColors[nutrient] // maybe get from nutrient
    const colors = ['#fff', baseColor]

    return colorFunctions.linear(value, range, colors)
  },
  linear (value, range = [0, 1], colors = ['#000', '#fff']) {
    return interpolateRegularGradient(colors, normalize(value, range))
  },
  autoZoneMapDebug (value) {
    return interpolateRdYlGn((value + 1) / 2)
  },
  phbb (value, index, zoneCount, category) {
    switch (category) {
    case 'caOApplicationMap':
      return phbbValueColorFunctions.getLinearValuesColor(index, zoneCount, ['#D5DDFF', '#010E47'], '#DBDBDB')
    case 'caORequiredNutrient':
      return phbbValueColorFunctions.getLinearValuesColor(index, zoneCount, ['#D5DDFF', '#010E47'], '#DBDBDB')
    case 'LimeApplicationMap':
      return phbbValueColorFunctions.getLinearValuesColor(index, zoneCount, ['#FFD5DD', '#47010E'], '#DBDBDB')
    case 'sand':
    case 'silt':
    case 'clayContent':
      return phbbValueColorFunctions.getLinearValuesColor(index, zoneCount, ['#FFFFE5', '#662506'], '#DBDBDB')
    case 'humusContent':
      return phbbValueColorFunctions.getHumusColor(value)
    case 'pH':
      return phbbValueColorFunctions.getPhColor(value)
    case 'phClassNutrientMap':
      return phbbValueColorFunctions.getPhClassColor(value)
    case 'soilEstimation':
      return phbbValueColorFunctions.getSoilEstimationColor(value)
    case 'soilGroup':
      return phbbValueColorFunctions.getSoilGroupColor(Number(value))
    default:
      throw Error(`map category ${category} not supported`)
    }
  }
}
