import {DEVICE_PIXEL_RATIO} from 'ol/has'

const canvas = document.createElement('canvas')
const context = canvas.getContext('2d')

export const stripes = (stripeColor, backgroundColor, lineCount = 2) => {
  const pixelRatio = DEVICE_PIXEL_RATIO
  canvas.width = 12 * pixelRatio
  canvas.height = 12 * pixelRatio

  context.fillStyle = backgroundColor
  context.fillRect(0, 0, canvas.width, canvas.height)

  const line = (from, to) => {
    context.moveTo(...from)
    context.lineTo(...to)
  }

  context.lineWidth = 8 / lineCount
  context.lineCap = 'round'
  context.strokeStyle = stripeColor

  new Array(lineCount + 1).fill(null).forEach((x, i) => {
    const start = [i * canvas.width * 2 / lineCount - canvas.width * 2 / lineCount, 0]
    const end = [i * canvas.width * 2 / lineCount, canvas.height]
    line(start, end)
  })

  context.stroke()

  return context.createPattern(canvas, 'repeat')
}

export const squareDots = (dotColor, backgroundColor, squareRatio = 0.5) => {
  const pixelRatio = DEVICE_PIXEL_RATIO
  canvas.width = 12 * pixelRatio
  canvas.height = 12 * pixelRatio

  context.fillStyle = backgroundColor
  context.fillRect(0, 0, canvas.width, canvas.height)

  context.fillStyle = dotColor
  context.fillRect(0, 0, canvas.width * squareRatio, canvas.height * squareRatio)

  return context.createPattern(canvas, 'repeat')
}

export const dickbutt = () => {
  const img = document.createElement('img')
  img.src = 'https://s-media-cache-ak0.pinimg.com/236x/b4/18/17/b41817ac353b3a31279117b05700dde8--stuff-stuff-funny-stuff.jpg'
  return context.createPattern(img, 'repeat')
}
