import {colorFunctions} from '@helpers/map-color-schemes'

const defaultColor = '#fff'

const getHumusColor = value => {
  switch (true) {
  case value < 1 : return '#FFFF73'
  case value < 2 : return '#FEFDC0'
  case value < 3 : return '#FFC780'
  case value < 4 : return '#FEAA00'
  case value < 8 : return '#A87000'
  case value < 15 : return '#734D00'
  case value < 30: return '#403200'
  case value >= 30: return '#737300'
  default: return defaultColor
  }
}

const getPhColor = value => {
  switch (true) {
  case value < 3.5 : return '#FF2900'
  case value < 4 : return '#FF4A08'
  case value < 4.5 : return '#FF7310'
  case value < 5 : return '#FF9C18'
  case value < 5.5 : return '#FFDE20'
  case value < 6 : return '#E6EE31'
  case value < 6.5 : return '#9CBD41'
  case value < 7 : return '#5A835A'
  case value < 7.5 : return '#39526A'
  case value < 8 : return '#29416A'
  case value < 8.5 : return '#183173'
  default: return '#08187B'
  }
}

const getPhClassColor = value => {
  switch (value) {
  case 'A': return '#2a1c7e'
  case 'B': return '#169b3e'
  case 'C': return '#f9ea1f'
  case 'D': return '#f7aa10'
  case 'E': return '#e64415'
  default: return defaultColor
  }
}

const getSoilEstimationColor = value => {
  switch (value) {
  case 'S': return '#ffff00'
  case 'Sl': return '#ffffa6'
  case 'lS': return '#ffcc00'
  case 'SL': return '#ffd47a'
  case 'sL': return '#ccb31a'
  case 'L': return '#bf801a'
  case 'LT': return '#d9d9d9'
  case 'T': return '#808080'
  default: return defaultColor
  }
}

const getSoilGroupColor = value => {
  switch (value) {
  case 1: return '#FDF8CD' // S
  case 2: return '#F5E70B' // l'S
  case 3: return '#D0B473' // lS
  case 4: return '#E9A803' // sL/ul
  case 5: return '#C55552' // tL/T
  case 6: return '#187802' // Mo
  default: return defaultColor
  }
}

const getLinearValuesColor = (zoneNumber, zoneCount, colors, nullValueColor) => {
  if (zoneNumber !== undefined && zoneCount !== undefined) {
    return colorFunctions.linear(zoneNumber, [0, zoneCount], colors)
  } else {
    return nullValueColor
  }
}
const getValueFromFeature = feature => {
  let {value} = feature.getProperties()
  if (isNaN(value)) {
    throw Error('value is not a number')
  }
  return Number(value)
}

export const phbbValueColorFunctions = {
  getHumusColor,
  getPhColor,
  getPhClassColor,
  getSoilEstimationColor,
  getSoilGroupColor,
  getLinearValuesColor
}

const clayColorFunction = feature => {
  const {zone: zoneNumber, zoneCount} = feature.getProperties()
  let colors = ['#FFFFE5', '#662506']
  let nullValueColor = '#DBDBDB'
  return getLinearValuesColor(zoneNumber, zoneCount, colors, nullValueColor)
}

export const phbbFeatureColorFunctions = {
  caOApplicationMap: feature => {
    const {zone: zoneNumber, zoneCount} = feature.getProperties()
    const colors = ['#D5DDFF', '#010E47']
    const nullValueColor = '#DBDBDB'
    return getLinearValuesColor(zoneNumber, zoneCount, colors, nullValueColor)
  },
  caORequiredNutrient: feature => {
    const {zone: zoneNumber, zoneCount} = feature.getProperties()
    const colors = ['#D5DDFF', '#010E47']
    const nullValueColor = '#DBDBDB'
    return getLinearValuesColor(zoneNumber, zoneCount, colors, nullValueColor)
  },
  LimeApplicationMap: feature => {
    const {zone: zoneNumber, zoneCount} = feature.getProperties()
    const colors = ['#FFD5DD', '#47010E']
    const nullValueColor = '#DBDBDB'
    return getLinearValuesColor(zoneNumber, zoneCount, colors, nullValueColor)
  },
  clayContent: clayColorFunction,
  sand: clayColorFunction,
  silt: clayColorFunction,
  humusContent: feature => {
    const value = getValueFromFeature(feature)
    return getHumusColor(value)
  },
  pH: feature => {
    const value = getValueFromFeature(feature)
    return getPhColor(value)
  },
  phClassNutrientMap: feature => {
    const {value} = feature.getProperties()
    return getPhClassColor(value)
  },
  soilEstimation: feature => {
    const {value} = feature.getProperties()
    return getSoilEstimationColor(value)
  },
  soilGroup: feature => {
    const value = getValueFromFeature(feature)
    return getSoilGroupColor(value)
  },
  defaultLinear: feature => {
    const {zone: zoneNumber, zoneCount} = feature.getProperties()
    const colors = ['#ffff00', '#0000ff']
    const nullValueColor = '#DBDBDB'
    return getLinearValuesColor(zoneNumber, zoneCount, colors, nullValueColor)
  }
}
