export default {
  probingGeometry: {
    active: 'rgb(30, 60, 245)',
    // point planning
    inactive: 'rgb(74, 71, 68)',
    new: 'rgb(85,57,243)',
    deleted: 'rgb(205, 50, 68)',
    // result preview
    hasResult: 'rgb(85,57,243)',
    open: 'rgb(74, 71, 68)'
  },
  classification: {
    A: 'rgb(149, 245, 249)',
    B: 'rgb(255, 255, 0)',
    C: 'rgb(85, 255, 0)',
    D: 'rgb(255, 170, 0)',
    E: 'rgb(255, 0, 0)',
    F: 'rgb(255, 0, 255)'
  },
  nutrientBaseColors: {
    'ph': '#0000ff',
    'phosphorus': '#00ff00',
    'potassium': '#ff0000',
    'magnesium': '#f0f000'
  },
  managementZoneMap: {
    1: ['#00FF00'],
    3: ['#FF0000', '#00DC00', '#0000FF'],
    5: ['#FF0000', '#FFFF00', '#00DC00', '#00FFFF', '#0000FF'],
    7: ['#FF0000', '#FF9600', '#FFFF00', '#00DC00', '#00FFFF', '#009BFF', '#0000FF'],
    9: ['#FF0000', '#FF9600', '#FFFF00', '#8CDC00', '#00DC00', '#00A500', '#00FFFF', '#009B9B', '#0000FF'],
    11: ['#FF0000', '#FF9600', '#FFFF00', '#A0DC00', '#8CDC00', '#00DC00', '#00A500', '#00A58C', '#00FFFF', '#009B9B', '#0000FF'],
    13: ['#FF0000', '#FF9600', '#FFFF00', '#C8DC00', '#A0DC00', '#8CDC00', '#00DC00', '#00A500', '#00A078', '#00A58C', '#00FFFF', '#009B9B', '#0000FF']
  },
  pastel: ['#FF6C65', '#fdfd96', '#cafd96', '#aad5fe', '#035fba']
}
